@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@600&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Source+Serif+4&display=swap');

// Our variables
$base-font-family: 'Source Serif 4', "Apple SD Gothic Neo", AppleGothic, NanumBarunGothic, "Malgun Gothic", Dotum, sans-serif;
$monospace-font-family: 'IBM Plex Mono', Menlo, Consolas, "Courier New", DotumChe, monospace;
$header-font-family: 'Fira Sans Condensed', sans-serif;
$base-font-size:   18px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2568ba;

$grey-color:       #757575;
$grey-color-light: adjust-color($grey-color, $lightness: 45%);
$grey-color-dark:  adjust-color($grey-color, $lightness: -20%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// whiteglass also includes a mixin for defining media queries.
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit * 0.5;
//         padding-left: $spacing-unit * 0.5;
//     }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}



// Import partials from the `whiteglass` theme.
@import "whiteglass";
